import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejectedWithValue, isAnyOf} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {getProviders} from "./providerSlice";
import {getProviderSites} from "./providerSiteSlice";

export const getBoxes = createAsyncThunk(
    'box/fetchAll',
    async ({queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getBox = createAsyncThunk(
    'box/fetchOne',
    async (id, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs/${id}`);
            return response.data.box;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postBox = createAsyncThunk(
    'box/create',
    async (data, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/box/boxs`, data);
            return response.data.box
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putBox = createAsyncThunk(
    'box/update',
    async ({id, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/box/boxs/${id}`, data);
            return response.data.box
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteBox = createAsyncThunk(
    'box/delete',
    async (id, {dispatch, rejectWithValue}) => {
        try {
            await limApi.delete(`/box/boxs/${id}`)
            await dispatch(getBoxes())
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getBoxTypes = createAsyncThunk(
    'box/fetchBoxTypes',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/box/boxtypes');
            return response.data.boxTypes;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getBoxStatusTypes = createAsyncThunk(
    'box/fetchBoxStatusTypes',
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/box/boxstatustypes');
            return response.data.boxStatusTypes;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const isPendingAction = isPending(getBox, getBoxTypes, getBoxStatusTypes);
const isFulfilledAction = isFulfilled(getBox, getBoxTypes, getBoxStatusTypes);
const isRejectedAction = isRejectedWithValue(getBox, getBoxTypes, getBoxStatusTypes);

const boxSlice = createSlice({
    name: "box",
    initialState: {
        list: [],
        listLoading: undefined,
        box: undefined,
        loading: undefined,
        boxTypes: [],
        boxStatusTypes: [],
        boxStatusTypesLoading: undefined,
    },
    reducers: {
        resetBoxes: state => {
            state.list = [];
            state.boxTypes = [];
            state.boxStatusTypes = [];
        },
        resetBox: state => {
            state.box = undefined;
            state.boxTypes = [];
            state.boxStatusTypes = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBoxes.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getBoxes.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getBoxes.rejected, state => {
                state.listLoading = false;
            })
            .addCase(getBox.fulfilled, (state, action) => {
                state.box = action.payload;
            })
            .addCase(putBox.fulfilled, (state, action) => {
                state.box = action.payload;
            })
            .addCase(getBoxTypes.fulfilled, (state, action) => {
                state.boxTypes = action.payload;
            })
            .addCase(getBoxStatusTypes.pending, (state) => {
                state.boxStatusTypesLoading = true;
            })
            .addCase(getBoxStatusTypes.fulfilled, (state, action) => {
                state.boxStatusTypes = action.payload;
                state.boxStatusTypesLoading = undefined;
            })
            .addCase(getBoxStatusTypes.rejected, (state) => {
                state.boxStatusTypesLoading = undefined;
            })
            // matchers
            .addMatcher(isAnyOf(isPendingAction), (state, action) => {
                state.loading = true
            })
            .addMatcher(isAnyOf(isFulfilledAction), (state) => {
                state.loading = undefined
            })
            .addMatcher(isAnyOf(isRejectedAction), (state) => {
                state.loading = undefined
            })
    }
});

export const fetchBoxInformation = () => async dispatch => {
    await dispatch(getProviders({queryParams: 'currentPage=1&sortOrders=[{"field":"providerName","direction":"asc"}]'}))
    await dispatch(getProviderSites({queryParams: 'currentPage=1&sortOrders=[{"field":"siteName","direction":"asc"}]'}))
    await dispatch(getBoxTypes())
    await dispatch(getBoxStatusTypes())
}

export const {resetBoxes, resetBox} = boxSlice.actions;

export default boxSlice.reducer;