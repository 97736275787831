const initialState = [];

const ErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GLOBAL_ERROR": {
            return {
                ...state,
                globalError: action.payload
            };
        }
        case 'POST_ERROR':
            return {
                ...state,
                ...action.payload
            };
        case "ERROR_EMPTY":
            return initialState;
        default:
            return state;
    }
}

export default ErrorReducer;