export const reports = [
	{
		name: "Reports",
		type: "dropDown",
		icon: "IoPieChartOutline",
		displayPosition: 50,
		key: "ReportsMenu",
		sub: [
			{
				icon: "BsClipboardCheck",
				name: "Providers",
				path: "/reports/providers",
				type: "link",
				displayPosition: 0,
				key: "CoreReportsProviders",
			},
			{
				icon: "FaWarehouse",
				name: "Provider Site",
				path: "/reports/provider-sites",
				type: "link",
				displayPosition: 10,
				key: "CoreReportsProviderSite",
			},
			{
				icon: "BsBox",
				name: "Box",
				path: "/reports/boxes",
				type: "link",
				displayPosition: 20,
				key: "CoreReportsBox"
			}
		]
	}
]

export const providerSite = [
	{
		name: "Provider Site",
		description: "Lorem ipsum dolor sit.",
		type: "dropDown",
		icon: "FaWarehouse",
		displayPosition: 10,
		key: "ProviderSiteMenu",
		sub: [
			{
				icon: "BsClipboardCheck",
				name: "Providers",
				path: "/provider/list",
				type: "link",
				key: "CoreProviderSiteProviders",
			},
			{
				icon: "FaWarehouse",
				name: "Sites",
				path: "/provider-sites/list",
				type: "link",
				key: "CoreProviderSiteSites",
			},
		]
	}
];

export const box = [
	{
		name: "Box",
		description: "Lorem ipsum dolor sit.",
		type: "link",
		displayPosition: 20,
		path: "/box/list",
		icon: "BsBox",
		key: "CoreBox"
	}
];

export const clients = [
	{
		name: "Clients",
		description: "Lorem ipsum dolor sit.",
		type: "dropDown",
		icon: "BsFillPersonLinesFill",
		displayPosition: 30,
		key: "ClientsMenu",
		sub: [
			{
				name: "Clients",
				description: "Lorem ipsum dolor sit.",
				type: "link",
				path: "/client/list",
				icon: "IoPeople",
				key: "CoreClientsClients"
			},
			{
				name: "Accounts",
				description: "Lorem ipsum dolor sit.",
				type: "link",
				path: "/account/list",
				icon: "RiArchiveDrawerFill",
				key: "CoreClientsAccounts"
			},
		]
	}
]

export const admin = [
	{
		name: "Admin",
		description: "Lorem ipsum dolor sit.",
		type: "dropDown",
		icon: "i-Lock-2",
		displayPosition: 40,
		key: "AdminMenu",
		sub: [
			{
				icon: "GrUserAdmin",
				name: "App Admin",
				type: "dropDown",
				key: "AdminAppAdminMenu",
				sub: [
					{
						icon: "i-Male",
						name: "Users",
						path: "/admin/user/list",
						type: "link",
						key: "CoreAdminAppAdminUsers"
					},
					{
						icon: "RiShieldUserLine",
						name: "Roles",
						path: "/admin/role/list",
						type: "link",
						key: "CoreAdminAppAdminRolesPermissions"
					}
				],
			},
		],
	},
];

export const navigations = [
	{
		name: "Dashboard",
		description: "Lorem ipsum dolor sit.",
		type: "link",
		icon: "i-Bar-Chart",
		path: "/dashboard",
		displayPosition: 0,
		key: "CoreDashboard",
	}
];


