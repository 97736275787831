import { lazy } from "react";

const UserList = lazy(() => import("./users/UserList"));
const CreateUser = lazy(()=> import('./users/CreateUser'));
const EditUser = lazy(()=> import('./users/EditUser'));
const RolesList = lazy(() => import("./roles/RolesList"));
const CreateRole = lazy(() => import("./roles/CreateRole"));
const EditRole = lazy(() => import("./roles/EditRole"));

const clientAdminRoutes = [
    {
        path: "/admin/user/list",
        component: UserList,
        key: 'View_CoreAdminAppAdminUsers'
    },
    {
        path: "/admin/user/create",
        component: CreateUser,
        key: 'Create_CoreAdminAppAdminUsers'
    },
    {
        path: "/admin/user/:idUser([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        component: EditUser,
        exact: true,
        key: "Edit_CoreAdminAppAdminUsers"
    },
    // {
    //     path: '/admin/user/bulk-import',
    //     component: UserBulkImport,
    //     exact: true,
    //     key: "BulkImport_CoreAdminClientAdministrationUser"
    // },
    {
        path: "/admin/role/list",
        component: RolesList,
        key: 'View_CoreAdminAppAdminRolesPermissions'
    },
    {
        path: "/admin/role/create",
        component: CreateRole,
        exact: true,
        key: 'View_CoreAdminAppAdminRolesPermissions'
    },
    {
        path: "/admin/role/:idRole",
        component: EditRole,
        exact: true,
        key: 'View_CoreAdminAppAdminRolesPermissions'
    }
];



export default clientAdminRoutes;
