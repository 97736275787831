// import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import { classList } from "@utils";
// import DropDownMenuItem from "./DropDownMenuItem";
// import {Can} from "../../app/casl/Can";
// import i18n from "i18next";
// import SidenavIcons from "./SidenavIcons";
//
// class DropDownMenu extends Component {
//   state = {
//     open: false
//   };
//
//   onItemClick = e => {
//     e.preventDefault();
//     this.setState({ open: !this.state.open });
//   };
//
//   renderLevels = items =>
//     items.map((item, i) => {
//       if (item.sub) {
//         return (
//           <DropDownMenuItem key={i} item={item} closeSecSidenav={this.props.closeSecSidenav}>
//             {this.renderLevels(item.sub)}
//           </DropDownMenuItem>
//         );
//       } else {
//           if(item.key) {
//               return (
//                   <Can I={'View'} a={`${item.key}`} key={i}>
//                       <li
//                           key={i}
//                           className={classList({
//                               "nav-item": true,
//                               open: this.state.open
//                           })}
//                           onClick={this.props.closeSecSidenav}
//                       >
//                           <Link to={item.path}>
//                               <SidenavIcons icon={item.icon} />
//                               <span className="item-name">{i18n.t(item.name)}</span>
//                           </Link>
//                       </li>
//                   </Can>
//               );
//           }
//
//         return (
//           <li
//             key={i}
//             className={classList({
//               "nav-item": true,
//               open: this.state.open
//             })}
//             onClick={this.props.closeSecSidenav}
//           >
//             <Link to={item.path}>
//               <SidenavIcons icon={item.icon} />
//               <span className="item-name">{i18n.t(item.name)}</span>
//             </Link>
//           </li>
//         );
//       }
//     });
//
//   render() {
//     return <ul className="childNav">{this.renderLevels(this.props.menu)}</ul>;
//   }
// }
//
// export default DropDownMenu;



import React, { useState } from "react";
import { Link } from "react-router-dom";
import { classList } from "@utils";
import DropDownMenuItem from "./DropDownMenuItem";
import { Can } from "../../app/casl/Can";
import i18n from "i18next";
import SidenavIcons from "./SidenavIcons";

const DropDownMenu = ({ menu, closeSecSidenav }) => {
    const [open] = useState(false);

    // const onItemClick = (e) => {
    //     e.preventDefault();
    //     setOpen((prevState) => !prevState);
    // };

    const renderLevels = (items) =>
        items.map((item, i) => {
            if (item.sub) {
                return (
                    <DropDownMenuItem key={i} item={item} closeSecSidenav={closeSecSidenav}>
                        {renderLevels(item.sub)}
                    </DropDownMenuItem>
                );
            } else {
                if (item.key) {
                    return (
                        <Can I={"View"} a={`${item.key}`} key={i}>
                            <li
                                key={i}
                                className={classList({
                                    "nav-item": true,
                                    open: open,
                                })}
                                onClick={closeSecSidenav}
                            >
                                <Link to={item.path}>
                                    <SidenavIcons icon={item.icon} />
                                    <span className="item-name">{i18n.t(item.name)}</span>
                                </Link>
                            </li>
                        </Can>
                    );
                }

                return (
                    <li
                        key={i}
                        className={classList({
                            "nav-item": true,
                            open: open,
                        })}
                        onClick={closeSecSidenav}
                    >
                        <Link to={item.path}>
                            <SidenavIcons icon={item.icon} />
                            <span className="item-name">{i18n.t(item.name)}</span>
                        </Link>
                    </li>
                );
            }
        });

    return <ul className="childNav">{renderLevels(menu)}</ul>;
};

export default DropDownMenu;




