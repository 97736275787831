import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setLayoutSettings,
  setDefaultSettings
} from "app/redux/slices/layoutSlice";
import { withRouter } from "react-router-dom";
import { SignOutButton } from "../../auth/SignOutButton";
import { withTranslation} from 'react-i18next';
import LanguageSwitcher from "../SharedComponents/LanguageSwitcher";
import {Can} from "../../casl/Can";
import {TbGridDots} from "react-icons/tb";
import LazyImageAPI from "../../../@gull/components/LazyImageAPI";
import {withMsal} from "@azure/msal-react";

class Layout1Header extends Component {

	handleMenuClick = () => {
		let { setLayoutSettings, settings } = this.props;
		setLayoutSettings({
			layout1Settings: {
				leftSidebar: {
					open: settings.layout1Settings.leftSidebar.secondaryNavOpen
						? true
						: !settings.layout1Settings.leftSidebar.open,
					secondaryNavOpen: false,
				},
			},
		});
	};

	render() {
		let { user, t } = this.props;
		const domainHint = this.props.msalContext.instance.getActiveAccount()?.idTokenClaims?.idp

		let initials = "";
		const client_name = user.firstName + " " + user.lastName;
		if (user.firstName) {
			initials = Array.prototype.map
				.call(client_name.split(" "), function (x) {
					return x.substring(0, 1).toUpperCase();
				})
				.join("")
				.substring(0, 3);
		}

		const getApplicationHref = (application) => {
			if (application.applicationType === 'API') {
				return {
					href: application.url,
					target: "_blank"
				}
			}
			return {
				href: `${application.url}?loginHint=${user.email}${domainHint ? `&domain_hint=${domainHint}` : ''}`
			}
		}

		return (
			<div className="main-header">
				<div className="menu-toggle" onClick={this.handleMenuClick}>
					<div></div>
					<div></div>
					<div></div>
				</div>

				<div className="logo client-logo mr-4 position-relative">
					<Link to="/">
						<img src="/assets/images/IGL_BOX_Logo.png" alt="Logo"/>
					</Link>
				</div>

				<div style={{ margin: "auto" }}></div>

				<div className="header-part-right">
					<div className="d-none d-lg-flex align-items-center mr-3">
						<Link to="/search">
							<i className="search-icon text-dark i-Magnifi-Glass1 text-16 cursor-pointer"/>
						</Link>
					</div>

					<div className="mr-3">
						<LanguageSwitcher className="selectpicker border-0 rounded h-36 light-bg text-center text-uppercase" />
					</div>

					{user.applications && user.applications.length > 1 && (
						<Dropdown className="mr-3" title={t("Switch to")}>
							<DropdownToggle as="span" className="toggle-hidden cursor-pointer">
								<TbGridDots size="24px" color="#5f6368" />
							</DropdownToggle>

							<DropdownMenu className="mt-2">
								<Dropdown.Header>{t("Switch to")}</Dropdown.Header>
								{user.applications
									.filter(application => application.idApplication !== process.env.REACT_APP_IGL_API_ID_APPLICATION)
									.map((application, index) => (
										<Dropdown.Item key={index} {...getApplicationHref(application)}>
											<span className="d-flex justify-content-start align-items-center">
												<span style={{width: "50px", height: "50px"}} className="p-1 position-relative rounded bg-off-white d-flex align-items-center flex-wrap mr-2 box-shadow-1">
													<LazyImageAPI idImage={application.idImage} />
												</span>
												{application.name}
											</span>
										</Dropdown.Item>
									))}
							</DropdownMenu>
						</Dropdown>
					)}

					<Dropdown>
						<DropdownToggle as="span" className="toggle-hidden cursor-pointer">
							<div className={`avatar-circle-xs`}>
								<span className="initials" data-initials={initials} />
							</div>
						</DropdownToggle>
						<DropdownMenu>
							<Dropdown.Header>
								<i className="i-Lock-User mr-1"/>
								{client_name}
							</Dropdown.Header>
							<Can I="View" a="CoreGeneralUserAccountUserProfile">
								<Dropdown.Item as={Link} to="/user/profile">
									{t("User Profile")}
								</Dropdown.Item>
							</Can>
							<Dropdown.Divider />
							<SignOutButton />
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
		);
	}
}

Layout1Header.propTypes = {
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  setDefaultSettings: PropTypes.func.isRequired,
  setLayoutSettings: PropTypes.func.isRequired,
  user: state.user,
  settings: state.layout.settings
});

export default withTranslation()(withRouter(
	withMsal(
	  connect(mapStateToProps, {
		setLayoutSettings,
		setDefaultSettings
	  })(Layout1Header)
	)
));

