import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {getCountries} from "./countrySlice";
import sgMailConfig from "../../apis/sgMailConfig";
import {languageCodeOnly} from "../../../i18n";
import sgMail from "../../apis/sgMail";

export const getBoxUsers = createAsyncThunk(
    'boxUser/fetchAll',
    async (idBox, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/boxs/${idBox}/users?pageSize=10000`);
            return response.data.boxUsers;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postBoxUser = createAsyncThunk(
    'boxUser/create',
    async ({idBox, data}, {rejectWithValue, dispatch, getState}) => {
        try {
            const response = await limApi.post(`/box/boxs/${idBox}/users`, {
                idBox,
                idClientAccountUser: data.idClientAccountUser,
                activityBeginDate: data.fromDate,
                activityEndDate: data.toDate,
                enabled: true
            });

            await dispatch(getBoxUsers(idBox));
            await dispatch(getCountries());

            const providerSite = getState().providerSite.list.find(site => site.idProviderSite === getState().box.box.idProviderSite);
            const boxNumber = `${providerSite.siteName} - Box ${getState().box.box.boxNumber}`;

            const msg = {
                from: sgMailConfig.from,
                personalizations: [{
                    to: [
                        {
                            email: data.user.email
                        }
                    ],
                    dynamic_template_data: {
                        user: {
                            firstName: data.user.firstName,
                            lastName: data.user.lastName
                        },
                        box: {
                            boxNumber: boxNumber
                        },
                        providerSite: {
                            siteName: providerSite.siteName,
                            address1: providerSite.address1,
                            address2: providerSite.address2,
                            zipCode: providerSite.zipCode,
                            city: providerSite.city,
                            country: (getState().country.list.find((country) => country.isO2 === providerSite.country))?.countryNameEN
                        },
                        appUrl: sgMailConfig.appUrl
                    }
                }],
                template_id: sgMailConfig.templatesId.box.addUser[languageCodeOnly(data.user.cultureLanguageCode)]
            };

            await sgMail.send(msg).catch(err => {
                console.error(err)
            })

            return response.data.boxUser;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteBoxUser = createAsyncThunk(
    'boxUser/create',
    async ({idBox, idClientAccountUser, user}, {rejectWithValue, dispatch, getState}) => {
        try {
            await limApi.delete(`/box/boxs/${idBox}/users/${idClientAccountUser}`);

            await dispatch(getBoxUsers(idBox));
            await dispatch(getCountries());

            const providerSite = getState().providerSite.list.find(site => site.idProviderSite === getState().box.box.idProviderSite)
            const boxNumber = `${providerSite.siteName} - Box ${getState().box.box.boxNumber}`;

            const msg = {
                from: sgMailConfig.from,
                personalizations: [{
                    to: [
                        {
                            email: user.email
                        }
                    ],
                    dynamic_template_data: {
                        user: {
                            firstName: user.firstName,
                            lastName: user.lastName
                        },
                        box: {
                            boxNumber: boxNumber
                        },
                        providerSite: {
                            siteName: providerSite.siteName,
                            address1: providerSite.address1,
                            address2: providerSite.address2,
                            zipCode: providerSite.zipCode,
                            city: providerSite.city,
                            country: (getState().country.list.find((country) => country.isO2 === providerSite.country))?.countryNameEN
                        },
                        appUrl: sgMailConfig.appUrl
                    }
                }],
                template_id: sgMailConfig.templatesId.box.removeUser[languageCodeOnly(user.cultureLanguageCode)]
            };

            await sgMail.send(msg).catch(err => {
                console.error(err)
            })

            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const boxUserSlice = createSlice({
    name: "boxUser",
    initialState: {
        list: [],
        listLoading: false
    },
    reducers: {
        resetBoxUsers: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBoxUsers.pending, state => {
                // if (state.list.length === 0) {
                //     state.listLoading = true;
                // }
            })
            .addCase(getBoxUsers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getBoxUsers.rejected, state => {
                state.listLoading = false;
            })
    }
});

export const {resetBoxUsers} = boxUserSlice.actions;

export default boxUserSlice.reducer;