import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getRolePermissions = createAsyncThunk(
    'rolePermissions/fetchAll',
    async ({idRole}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/role/${idRole}/permissions`);
            return response.data.rolePermissions;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putRolePermissions = createAsyncThunk(
    'rolePermissions/update',
    async ({idRole, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/role/${idRole}/permissions`, data);
            return response.data.rolePermissions;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const rolePermissionsSlice = createSlice({
    name: 'rolePermissions',
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetRolePermissions: (state) => {
            state.list = [];
        }
    },
    extraReducers: {
        [getRolePermissions.pending]: (state) => {
            state.listLoading = true;
        },
        [getRolePermissions.fulfilled]: (state, action) => {
            state.list = action.payload;
            state.listLoading = undefined;
        },
        [getRolePermissions.rejected]: (state) => {
            state.listLoading = undefined;
        },
        [putRolePermissions.fulfilled]: (state, action) => {
            state.list = action.payload;
        }
    }
});

export const {resetRolePermissions} = rolePermissionsSlice.actions;

export default rolePermissionsSlice.reducer;