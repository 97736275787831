import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getProviderSiteHistory = createAsyncThunk(
    "providerSiteHistory/fetchAll",
    async ({idProviderSite, queryParams = 'sortOrders=[{"field":"creationDate","direction":"desc"}]'}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/providersites/${idProviderSite}/history?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const providerSiteHistorySlice = createSlice({
    name: "providerSiteHistory",
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetProviderSiteHistory: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProviderSiteHistory.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getProviderSiteHistory.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getProviderSiteHistory.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const {resetProviderSiteHistory} = providerSiteHistorySlice.actions;

export default providerSiteHistorySlice.reducer;