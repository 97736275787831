const layout1Settings = {
  leftSidebar: {
    theme: "sidebar-slate-gray",
    open: true,
    secondaryNavOpen: false,
  },
  searchBox: {
    open: false
  }
};

export default layout1Settings;
