// import React, { Component } from "react";
// import { classList } from "@utils";
// import {Link} from "react-router-dom";
// import {Can} from "../../app/casl/Can";
// import i18n from "i18next";
// import SidenavIcons from "./SidenavIcons";
//
// class DropDownMenuItem extends Component {
//   state = {
//     collapsed: false
//   };
//   // elementRef = React.createRef();
//
//   componentHeight = 0;
//   // calcaulateHeight(node) {
//   //   if (node.name !== "child") {
//   //     for (let child of node.children) {
//   //       this.calcaulateHeight(child);
//   //     }
//   //   }
//   //   this.componentHeight += node.clientHeight;
//   //   return;
//   // }
//   componentDidMount() {
//     // this.calcaulateHeight(this.elementRef);
//
//     // OPEN DROPDOWN IF CHILD IS ACTIVE
//     // let { location } = this.props;
//     // for (let child of this.elementRef.children) {
//     //   if (child.getAttribute("href") === location.pathname) {
//     //     this.setState({ collapsed: false });
//     //   }
//     // }
//   }
//   onItemClick = () => {
//     this.setState({ collapsed: !this.state.collapsed });
//   };
//
//   render() {
//     let { collapsed } = this.state;
//     let { children } = this.props;
//     let { name, icon, path, key } = this.props.item;
//
//     return (
//         <li
//             className={classList({
//               "nav-item dropdown-sidemenu": true,
//               open: !collapsed
//             })}
//         >
//           {!path && key &&(
//               <Can I={'View'} a={key}>
//                 <div onClick={this.onItemClick}>
//                   <SidenavIcons icon={icon} />
//                   <span className="item-name">{i18n.t(name)}</span>
//                   <i className="dd-arrow i-Arrow-Down"></i>
//                 </div>
//               </Can>
//           )}
//
//           {!path && !key && (
//               <div onClick={this.onItemClick}>
//                 <SidenavIcons icon={icon} />
//                 <span className="item-name">{i18n.t(name)}</span>
//                 <i className="dd-arrow i-Arrow-Down"></i>
//               </div>
//           )}
//
//           {path && (
//               <Link to={path} style={{paddingLeft: "40px"}} onClick={this.props.closeSecSidenav}>
//                 <SidenavIcons icon={icon} />
//                 <span className="item-name">{i18n.t(name)}</span>
//                 {/*<i onClick={this.onItemClick} className="dd-arrow i-Arrow-Down"/>*/}
//               </Link>
//           )}
//
//           <ul
//               className="submenu"
//               // ref={el => (this.elementRef = el)}
//               style={
//                 collapsed
//                     ? { maxHeight: "0px" }
//                     : { maxHeight: "100%" }
//               }
//           >
//             {children}
//           </ul>
//         </li>
//     );
//   }
// }
//
// export default DropDownMenuItem;

import React, { useState } from "react";
import { classList } from "@utils";
import { Link } from "react-router-dom";
import { Can } from "../../app/casl/Can";
import i18n from "i18next";
import SidenavIcons from "./SidenavIcons";

const DropDownMenuItem = ({ item, children, closeSecSidenav }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onItemClick = () => {
    setCollapsed(!collapsed);
  };

  const { name, icon, path, key } = item;

  return (
      <li
          className={classList({
            "nav-item dropdown-sidemenu": true,
            open: !collapsed,
          })}
      >
        {!path && key && (
            <Can I={"View"} a={key}>
              <div onClick={onItemClick}>
                <SidenavIcons icon={icon} />
                <span className="item-name">{i18n.t(name)}</span>
                <i className="dd-arrow i-Arrow-Down"></i>
              </div>
            </Can>
        )}

        {!path && !key && (
            <div onClick={onItemClick}>
              <SidenavIcons icon={icon} />
              <span className="item-name">{i18n.t(name)}</span>
              <i className="dd-arrow i-Arrow-Down"></i>
            </div>
        )}

        {path && (
            <Link
                to={path}
                style={{ paddingLeft: "40px" }}
                onClick={closeSecSidenav}
            >
              <SidenavIcons icon={icon} />
              <span className="item-name">{i18n.t(name)}</span>
            </Link>
        )}

        <ul
            className="submenu"
            style={collapsed ? { maxHeight: "0px" } : { maxHeight: "100%" }}
        >
          {children}
        </ul>
      </li>
  );
};

export default DropDownMenuItem;









