import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import {
	admin,
	navigations,
	providerSite,
	box,
	reports,
	clients
} from "../../navigations";
import { classList, isMobile } from "@utils";
import ScrollBar from "react-perfect-scrollbar";
import { DropDownMenu } from "@gull";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutSettings } from "app/redux/slices/layoutSlice";
import "react-loading-skeleton/dist/skeleton.css";
import CAN from "../../casl/Can";
import {useTranslation} from "react-i18next";
import SidenavIcons from "../../../@gull/components/SidenavIcons";

const Layout1Sidenav = () => {
	const [selectedItem, setSelectedItem] = useState(null);
	const dispatch = useDispatch();
	const settings = useSelector((state) => state.layout.settings);
	const {t} = useTranslation()

	const onMainItemMouseEnter = (item) => {
		if (item.type === "dropDown") {
			setSelected(item);
			openSecSidenav();
		} else {
			setSelected(item);
			closeSecSidenav();
		}
	};

	const onMainItemMouseLeave = () => {
		// closeSecSidenav();
	};

	const setSelected = (selectedItem) => {
		setSelectedItem(selectedItem);
	};

	const openSecSidenav = () => {
		dispatch(
			setLayoutSettings({
				layout1Settings: {
					leftSidebar: {
						secondaryNavOpen: true,
					},
				},
			}),
		);
	};

	const closeSecSidenav = () => {
		let other = {};

		if (isMobile()) {
			other.open = false;
		}

		dispatch(
			setLayoutSettings({
				layout1Settings: {
					leftSidebar: {
						...other,
						secondaryNavOpen: false,
					},
				},
			}),
		);
	};

	const closeSidenav = () => {
		dispatch(
			setLayoutSettings({
				layout1Settings: {
					leftSidebar: {
						open: false,
						secondaryNavOpen: false,
					},
				},
			}),
		);
	};

	const openSidenav = () => {
		dispatch(
			setLayoutSettings({
				layout1Settings: {
					leftSidebar: {
						open: true,
					},
				},
			}),
		);
	};

	useEffect(() => {
		if (selectedItem === null) closeSecSidenav();

		if (window) {
			if (window.innerWidth < 1200) {
				closeSidenav();
			} else {
				openSidenav();
			}
		}

		const listener = () => {
			if (window.innerWidth < 1200) {
				closeSidenav();
			} else {
				openSidenav();
			}
		}

		window.addEventListener("resize", listener, false);

		return () => {
			window.removeEventListener("resize", listener, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	const sideNav = [...navigations, ...providerSite, ...box, ...clients, ...reports, ...admin];

	return (
		<div className="side-content-wrap">
			<ScrollBar
				className={classList({
					"sidebar-left o-hidden rtl-ps-none": true,
					open: settings.layout1Settings.leftSidebar.open,
				})}
			>
				<ul className="navigation-left">
					{sideNav
						.sort((a, b) => a.displayPosition - b.displayPosition)
						.map((item, i) => {
							if (CAN("View", item.key) || !item.key) {
								const renderNavLink = (
									<NavLink className="nav-item-hold" to={item.path || "#"}>
										<SidenavIcons icon={item.icon} />
										<span className="nav-text">{t(item.name)}</span>
									</NavLink>
								);

								const renderExternalLink = (
									<a className="nav-item-hold" href={item.path} target={item.target}>
										<SidenavIcons icon={item.icon} />
										<span className="nav-text">{t(item.name)}</span>
									</a>
								);

								const renderPlaceholder = (
									<div className="nav-item-hold">
										<SidenavIcons icon={item.icon} />
										<span className="nav-text">{t(item.name)}</span>
									</div>
								);

								return (
									<li
										className={classList({
											"nav-item": true,
											active: selectedItem === item,
										})}
										onMouseEnter={() => {
											onMainItemMouseEnter(item);
										}}
										onMouseLeave={onMainItemMouseLeave}
										key={i}
									>
										{item.path && item.type !== "extLink" && renderNavLink}
										{item.path && item.type === "extLink" && renderExternalLink}
										{!item.path && renderPlaceholder}
										<div className="triangle" />
									</li>
								);
							}
							return null;
						})}
				</ul>
			</ScrollBar>

			<ScrollBar
				className={classList({
					"sidebar-left-secondary o-hidden rtl-ps-none": true,
					open: settings.layout1Settings.leftSidebar.secondaryNavOpen,
				})}
			>
				{selectedItem && selectedItem.sub && (
					<DropDownMenu menu={selectedItem.sub} closeSecSidenav={closeSecSidenav} />
				)}
				<span />
			</ScrollBar>

			<div
				onMouseEnter={closeSecSidenav}
				className={classList({
					"sidebar-overlay": true,
					open: settings.layout1Settings.leftSidebar.secondaryNavOpen,
				})}
			/>
		</div>
	);
};

export default Layout1Sidenav;
