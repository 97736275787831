import { lazy } from "react";

const BoxList = lazy(()=> import("./BoxList"));
const CreateBox = lazy(()=> import('./CreateBox'));
const EditBox = lazy( () => import('./EditBox'));


const clientAdminRoutes = [
    {
        path: "/box/list",
        component: BoxList,
        key: "View_CoreBox"
    },
    {
        path: "/box/create",
        component: CreateBox,
        key: "Create_CoreBox"
    },
    {
        path: '/box/edit/:id',
        component: EditBox,
        key: "Edit_CoreBox"
    },
];



export default clientAdminRoutes;
