import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getProviders = createAsyncThunk(
    "providers/fetchAll",
    async ({queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/providers?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProvider = createAsyncThunk(
    "providers/fetchOne",
    async (idProvider, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/providers/${idProvider}`);
            return response.data.provider;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProvider = createAsyncThunk(
    "providers/create",
    async (data, {rejectWithValue}) => {
        try {
            const response = await limApi.post('/box/providers', data);
            return response.data.provider;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putProvider = createAsyncThunk(
    "providers/update",
    async ({idProvider, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/box/providers/${idProvider}`, {idProvider, ...data});
            return response.data.provider;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProvider = createAsyncThunk(
    "providers/delete",
    async (idProvider, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/box/providers/${idProvider}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getZoneTypes = createAsyncThunk(
    "providers/fetchZoneTypes",
    async (_, {rejectWithValue}) => {
        try {
            const response = await limApi.get('/zonetypes')
            return response.data.zoneTypes;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const providerSlice = createSlice({
    name: "providers",
    initialState: {
        list: [],
        listLoading: false,
        provider: null,
        loading: false,
        zoneTypeList: []
    },
    reducers: {
        resetProviders: state => {
            state.list = [];
        },
        resetProvider: state => {
            state.provider = null;
        },
        resetZoneTypeList: state => {
            state.zoneTypeList = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProviders.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getProviders.fulfilled, (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addCase(getProviders.rejected, state => {
                state.listLoading = false;
            })
            .addCase(getProvider.pending, state => {
                state.loading = true;
            })
            .addCase(getProvider.fulfilled, (state, action) => {
                state.loading = false;
                state.provider = action.payload;
            })
            .addCase(getProvider.rejected, state => {
                state.loading = false;
            })
            .addCase(putProvider.fulfilled, (state, action) => {
                state.provider = action.payload;
            })
            .addCase(getZoneTypes.fulfilled, (state, action) => {
                state.zoneTypeList = action.payload;
            })
    }
});

export const {resetProviders, resetProvider, resetZoneTypeList} = providerSlice.actions;

export default providerSlice.reducer;