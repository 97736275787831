const ClientReducer = (state = [], action) => {
    switch(action.type) {
        case 'GET_CLIENTS':
            const getClientAccounts = () => {
                const accounts = [];
                for (const item of action.payload) {
                    for (const account in item.accounts) {
                        accounts.push(item.accounts[account])
                    }
                }
                return accounts;
            }

            return {
                ...state,
                clientsList: [...action.payload],
                accountsList: getClientAccounts()
            };
        case 'GET_CLIENT':
        case 'POST_CLIENT':
        case 'PUT_CLIENT':
            return {
                ...state,
                client: action.payload
            };
        case 'RESET_CLIENTS':
            return {
                ...state,
                clientsList: undefined,
                accountsList: undefined
            }
        default:
            return state
    }
}

export default ClientReducer;