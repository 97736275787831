import React, {Suspense, useCallback, useEffect} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  setLayoutSettings,
  setDefaultSettings
} from "app/redux/slices/layoutSlice";

// import { GullLayouts } from ".";
import Layout1 from "./Layout1/Layout1";
import Customizer from "./SharedComponents/Customizer";
import {Loading} from "../../@gull";

const GullLayout = (props) => {
  let { /* activeLayout, */ route, settings} = props;
  // let Layout = GullLayouts[activeLayout];
  let Layout = Layout1;

  const initAppDirection = useCallback(() => {
        let { settings } = props;
        setTimeout(() => {
          document.documentElement.setAttribute('dir', settings.dir);
        });
      }, [props],
  );

  useEffect(() => {
      initAppDirection();
  }, [initAppDirection]);

  return (
      <Suspense fallback={<Loading/>}>
        <Layout routes={route.routes}/>
        {(settings.customizer.show && <Customizer/>)}
      </Suspense>
    );
}

const mapStateToProps = state => ({
  settings: state.layout.settings,
  // activeLayout: state.layout.settings.activeLayout,
  defaultSettings: state.layout.defaultSettings,
  user: state.user,
});

// GullLayout.contextType = AppContext;

export default withRouter(
  connect(mapStateToProps, { setLayoutSettings, setDefaultSettings })(
    GullLayout
  )
);
