import React from "react";
import {useState} from "react";

const useTheme = () => {
    const [themeName] = useState('theme-slate-gray');

    return themeName;
}

export default useTheme;

export const withTheme = Component => {
    const ComponentWithTheme = props => {
        const themeName = useTheme();
        return React.createElement(Component, Object.assign({}, props, {
            themeName
        }));
    }

    const componentName = Component.displayName || Component.name || "Component";
    ComponentWithTheme.displayName = `withTheme(${componentName})`;
    return ComponentWithTheme;
}