import { lazy } from "react";
const Dashboard1 = lazy(() => import("./dashboard1/Dashboard1"));
const Search = lazy(() => import("./search/Search"));

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: Dashboard1,
    key: "View_CoreDashboard"
  },
  {
    path: "/search",
    component: Search,
  },
];

export default dashboardRoutes;
