import { lazy } from "react";

const UserProfile = lazy(() => import("./profile/UserProfile"));

const userRoutes = [
	{
		path: "/user/profile",
		component: UserProfile,
		key: "View_CoreGeneralUserAccountUserProfile"
	}
];

export default userRoutes;