import React, {useEffect, useMemo} from "react";
import {useMsal} from "@azure/msal-react";
import {loginRequest as authLoginRequest} from "./authConfig";
import {MsalAuthenticationTemplate} from "@azure/msal-react";
import {EventType, InteractionType} from "@azure/msal-browser";
import {Loading} from "@gull";
import {Alert} from "react-bootstrap";
import localStorageService from "../services/localStorageService";
import useQuery from "../hooks/use-query";

function ErrorComponent({error}) {
    const styles = {
        position: 'fixed',
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, -10%)',
        borderRadius: 0,
        width: '50%'
    };

    return (
        <Alert variant="danger" style={styles}>
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>{error}</p>
        </Alert>
    );
}

function LoadingComponent() {
    return <Loading/>;
}

export const LoginRedirect = (props) => {
    const {instance} = useMsal();
    const {loginHint, ui_locales, domain_hint} = useQuery(true);
    const loginRequest = useMemo(() => {
        return {
            ...authLoginRequest,
            ...(loginHint ? {loginHint} : {}),
            extraQueryParameters: {
                ...(ui_locales ? {ui_locales} : {}),
                ...(domain_hint ? {domain_hint} : {}),
            }
        }
    }, [domain_hint, loginHint, ui_locales]);

    /**
     * Using the event API, you can register an event callback that will do something when an event is emitted.
     * When registering an event callback in a react component you will need to make sure you do 2 things.
     * 1) The callback is registered only once
     * 2) The callback is unregistered before the component unmounts.
     * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
     */
    useEffect(() => {
        const callbackId = instance.addEventCallback(async (event) => {

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
                //     if (event.interactionType === InteractionType.Redirect) {
                //        await instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
                //     } else if (event.interactionType === InteractionType.Popup) {
                //        await instance.loginPopup(b2cPolicies.authorities.forgotPassword)
                //             .catch(e => {
                //                 return;
                //             });
                //     }
                // }

                if (event.error && event.error.errorMessage.indexOf("AADB2C90091") > -1) {
                    await instance.loginRedirect(loginRequest)
                        .catch(e => {
                            return;
                        });
                }
            }

            if (event.eventType === EventType.LOGIN_SUCCESS) {
                if (event?.payload) {
                    /**
                     * We need to reject id tokens that were not issued with the default sign-in policy.
                     * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
                     * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                     */
                    if (event.payload.account.idTokenClaims.isForgotPassword) {
                        window.alert("Password has been reset successfully. \nPlease sign-in with your new password");
                        return await instance.logout();
                    }
                    localStorageService.setItem("authUserId", event.payload.account.localAccountId);
                    localStorageService.setItem("accessToken", event.payload.idToken);
                    instance.setActiveAccount(event.payload.account);
                }
            }

            if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                localStorageService.setItem("authUserId", event.payload.account.localAccountId);
                localStorageService.setItem("accessToken", event.payload.idToken);
                instance.setActiveAccount(event.payload.account);
            }

            if (event.eventType === EventType.HANDLE_REDIRECT_END && loginRequest.loginHint && instance.getActiveAccount() && instance.getActiveAccount().username !== loginRequest.loginHint) {
                // Logout user when try SSO with different account
                return await instance.loginRedirect({
                    ...loginRequest,
                    prompt: "login"
                });
            }

            if (event.eventType === EventType.LOGOUT_SUCCESS) {
                localStorageService.removeItem("authUserId");
                localStorageService.removeItem("accessToken");
            }
        });


        // async function loginRedirect() {
        //     await instance.handleRedirectPromise();
        //
        //     const accounts = instance.getAllAccounts();
        //     if (accounts.length === 0) {
        //         // No user signed in
        //         instance.loginRedirect(loginRequest);
        //     }
        // }
        //
        // loginRedirect();


        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance, loginRequest]);

    return (
        <>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
                errorComponent={ErrorComponent}
                loadingComponent={LoadingComponent}
            >
                { props.children }
            </MsalAuthenticationTemplate>
        </>
    );
}