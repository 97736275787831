/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
// Linkbox DEV APP
export const b2cPolicies = {
  names: {
    signIn: process.env.REACT_APP_AAD_B2C_POLICY_SI,
    forgotPassword: "B2C_1_LB_RESET",
  },
  authorities: {
    signIn: {
      authority:
          `https://${process.env.REACT_APP_AAD_NAME}.b2clogin.com/${process.env.REACT_APP_AAD_DOMAIN_NAME}/${process.env.REACT_APP_AAD_B2C_POLICY_SI}`,
    },
    forgotPassword: {
      authority:
          `https://${process.env.REACT_APP_AAD_NAME}.b2clogin.com/${process.env.REACT_APP_AAD_DOMAIN_NAME}/B2C_1_LB_RESET`,
    },
  },
  authorityDomain: `${process.env.REACT_APP_AAD_NAME}.b2clogin.com`,
};