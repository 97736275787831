import limApi from "../../apis/limApi";
import {reFetchImagesById} from "../slices/imageSlice";

export const getClients = () => async (dispatch) => {
    const response = await limApi.get('/clients?pageSize=1000');

    return dispatch({
        type: 'GET_CLIENTS',
        payload: response.data.clients
    })
}

export const getClient = (idClient) => async (dispatch) => {
    const response = await limApi.get(`/clients/${idClient}`);

    return dispatch({
        type: 'GET_CLIENT',
        payload: response.data.client
    })
}

export const postClient = (data) => async (dispatch) => {
    try {
        const response = await limApi.post("/clients", data);

        return dispatch({
            type: 'POST_CLIENT',
            payload: response.data.client
        })
    } catch (e) {
        return dispatch({
            type: 'POST_ERROR',
            payload: {code: e.code, message: e.message}
        })
    }
}

export const putClient = (idClient, data) => async (dispatch) => {
    try {
        const client = await limApi.put(`/clients/${idClient}`, {idClient: idClient, ...data}).then(response => response.data.client);
        await dispatch(getClients());

        const reFetchImages = [client.idLogoImage, client.idCoverImage].filter(image => image);
        if (reFetchImages.length) {
            await dispatch(reFetchImagesById(reFetchImages));
        }
        // await dispatch(getUserClientLogoId());
        return dispatch({
            type: 'PUT_CLIENT',
            payload: client
        })
    } catch (e) {
        return dispatch({
            type: 'POST_ERROR',
            payload: {code: e.code, message: e.message}
        })
    }
}

export const deleteClient = (idClient) => async (dispatch) => {
    try {
        await limApi.delete(`/clients/${idClient}`);
        await dispatch(getClients());

        return dispatch({
            type: 'DELETE_CLIENT',
            payload: true
        })
    } catch (e) {
        return dispatch({
            type: 'POST_ERROR',
            payload: {code: e.code, message: e.message}
        })
    }
}