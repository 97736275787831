import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./reducers/RootReducer";
import globalErrorListenerMiddleware from './middlewares/globalErrorListenerMiddleware';

export const Store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).prepend(globalErrorListenerMiddleware.middleware),
});
