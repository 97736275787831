import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {selectUserIdClientAccount} from "./userSlice";

export const getDashboardProviderSites = createAsyncThunk(
    'dashboard/fetchDashboardProviderSites',
    async ({queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/providers/providersites?${queryParams}`);
            return response.data.items;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getDashboardBoxByStatus = createAsyncThunk(
    "dashboard/fetchBoxByStatus",
    async (_, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            let url = `/dashboard/boxbystatus`;
            if (idClientAccount) {
                url = `/dashboard/${idClientAccount}/boxbystatus`;
            }

            const response = await limApi.get(url);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const initialState = {
    providerSites: [],
    providerSitesLoading: undefined,
    boxByStatus: [],
    boxByStatusLoading: undefined
}

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        resetDashboard: () => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDashboardProviderSites.pending, state => {
                state.providerSitesLoading = true;
            })
            .addCase(getDashboardProviderSites.fulfilled, (state, action) => {
                state.providerSitesLoading = undefined;
                state.providerSites = action.payload;
            })
            .addCase(getDashboardProviderSites.rejected, state => {
                state.providerSitesLoading = undefined;
            })
            .addCase(getDashboardBoxByStatus.pending, state => {
                state.boxByStatusLoading = true;
            })
            .addCase(getDashboardBoxByStatus.fulfilled, (state, action) => {
                state.boxByStatusLoading = undefined;
                state.boxByStatus = action.payload;
            })
            .addCase(getDashboardBoxByStatus.rejected, state => {
                state.boxByStatusLoading = undefined;
            })
    }
});

export const {resetDashboard} = dashboardSlice.actions;

export default dashboardSlice.reducer;