import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <div className="flex-grow-1"></div>
            <div className="app-footer">
                <div className="row">
                    <div className="col-md-9">
                        <img className="img-fluid mb-2" style={{
                            maxWidth: "150px"
                        }} src="/assets/images/Logo_Linkeepers_big.png" alt="LinkBox logo"/>
                        <p>
                            {t("Marketing logistics at the heart of your CSR approach")}
                        </p>
                    </div>
                </div>
                <div className="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
                    <div className="align-items-center">
                        <p className="m-0">&copy; 2022 Linkeepers {t("Powered by")} IGL</p>
                        <p className="m-0">{t("All rights reserved")}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Footer;
