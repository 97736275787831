import {createListenerMiddleware} from "@reduxjs/toolkit";

const globalErrorListenerMiddleware = createListenerMiddleware();

globalErrorListenerMiddleware.startListening({
    predicate: (action, currentState) => {
        if (currentState.error.globalError) return false;

        if (action.type === 'user/getUserInfo/rejected') {
            return true;
        }

        return false;
    },
    effect: (action, listenerApi) => {
        listenerApi.dispatch({
            type: "GLOBAL_ERROR",
            payload: {
                code: null,
                message: [action.error.message || '', action.error.stack || '', action.payload?.message || '', action.payload?.response?.request?.responseURL || ''].filter(error => error).join("<br />")
            },
        });
        listenerApi.cancelActiveListeners();
    },
});

export default globalErrorListenerMiddleware;