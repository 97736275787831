import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import boxRoutes from './views/box/boxRoutes';
import clientAdminRoutes from "./views/admin/clientAdminRoutes";
import userRoutes from "./views/user/userRoutes";
import providerSiteRoutes from './views/provider-site/providerSiteRoutes';
import clientRoutes from "./views/clients/clientRoutes";
import reportsRoutes from "./views/reports/reportsRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...dashboardRoutes,
      ...boxRoutes,
      ...clientAdminRoutes,
      ...userRoutes,
      ...providerSiteRoutes,
      ...clientRoutes,
      ...reportsRoutes,
      ...redirectRoute,
      ...errorRoute,

    ]
  }
];

export default routes;
