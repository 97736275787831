import React from 'react';
import {FaWarehouse} from "react-icons/fa";
import {BsClipboardCheck, BsBox, BsFillPersonLinesFill} from "react-icons/bs";
import {IoPieChartOutline, IoPeople} from "react-icons/io5";
import {AiTwotoneDatabase} from "react-icons/ai";
import {RiShieldUserLine, RiArchiveDrawerFill} from "react-icons/ri";
import {GrUserAdmin} from "react-icons/gr";


const Components = {
    FaWarehouse,
    BsClipboardCheck,
    BsBox,
    BsFillPersonLinesFill,
    IoPieChartOutline,
    AiTwotoneDatabase,
    IoPeople,
    RiShieldUserLine,
    RiArchiveDrawerFill,
    GrUserAdmin
};

const SidenavIcons = ({icon}) => {
    // component does exist
    if (typeof Components[icon] !== "undefined") {
        return React.createElement(Components[icon], {
            className: 'nav-icon'
        });
    }

    // component doesn't exist yet
    return <i className={`nav-icon ${icon}`} />;
};

export default SidenavIcons;
