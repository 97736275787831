import React, { Component } from "react";
import { renderRoutes } from "react-router-config";
import Layout1Sidenav from "./Layout1Sidenav";
import Footer from "../SharedComponents/Footer";
import Layout1Header from "./Layout1Header";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { classList } from "@utils";
import { setLayoutSettings } from "app/redux/slices/layoutSlice";
import {NotificationContainer} from "react-notifications";
import '../../../styles/app/themes/default.scss'
import {GullSearch} from "@gull";
import {withTheme} from "../../hooks/use-theme";

class Layout1 extends Component {
  handleSearchBoxClose = () => {
    let { setLayoutSettings } = this.props;
    setLayoutSettings({
        layout1Settings: {
            searchBox: {
                open: false
            }
        }
    });
  };

    render() {
    let { settings, routes } = this.props;

    return (
      <div>
        <div className={`app-admin-wrap layout-sidebar-large
        ${this.props.themeName}`}>
          <Layout1Header/>
          <Layout1Sidenav />
          {/* sidebar */}

          <div
            className={classList({
              "main-content-wrap d-flex flex-column": true,
              "sidenav-open": settings.layout1Settings.leftSidebar.open
            })}
          >
            {/*<Suspense fallback={<Loading />}>*/}
            <div className="main-content">{renderRoutes(routes)}</div>
            {/*</Suspense>*/}
            {(settings.footer.show && <Footer></Footer>)}
          </div>
        </div>
        <GullSearch
          open={settings.layout1Settings.searchBox.open}
          handleClose={this.handleSearchBoxClose}
        />
        <NotificationContainer/>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        setLayoutSettings: PropTypes.func.isRequired,
        settings: state.layout.settings
    }
};

export default connect(mapStateToProps, { setLayoutSettings })(withTheme(Layout1));

// import React from "react";
// import { renderRoutes } from "react-router-config";
// import Layout1Sidenav from "./Layout1Sidenav";
// import Footer from "../SharedComponents/Footer";
// import Layout1Header from "./Layout1Header";
// import PropTypes from "prop-types";
// import { classList } from "@utils";
// import { setLayoutSettings } from "app/redux/slices/layoutSlice";
// import {NotificationContainer} from "react-notifications";
// import '../../../styles/app/themes/default.scss'
// import {GullSearch} from "@gull";
// import {withTheme} from "../../hooks/use-theme";
// import {useSelector, useDispatch} from "react-redux";
//
// const Layout1 = ({routes, themeName}) => {
//     const {settings} = useSelector(state => state.layout);
//     useSelector(state => console.log(state));
//     const dispatch = useDispatch();
//
//     const handleSearchBoxClose = () => {
//         dispatch(setLayoutSettings(
//             settings.layout1Settings.searchBox.open = false
//         ))
//     }
//
//     return (
//         <div>
//             <div className={`app-admin-wrap layout-sidebar-large ${themeName}`}>
//                 <Layout1Header/>
//                 <Layout1Sidenav />
//                 {/* sidebar */}
//
//                 <div
//                     className={classList({
//                         "main-content-wrap d-flex flex-column": true,
//                         "sidenav-open": settings.layout1Settings.leftSidebar.open
//                     })}
//                 >
//                     {/*<Suspense fallback={<Loading />}>*/}
//                     <div className="main-content">{renderRoutes(routes)}</div>
//                     {/*</Suspense>*/}
//                     {(settings.footer.show && <Footer></Footer>)}
//                 </div>
//             </div>
//             <GullSearch
//                 open={settings.layout1Settings.searchBox.open}
//                 handleClose={handleSearchBoxClose}
//             />
//             <NotificationContainer/>
//         </div>
//     );
// };
//
// export default withTheme(Layout1);














