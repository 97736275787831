import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import limApi, {SYSTEM_ADMIN_ROLE} from "../../apis/limApi";
import {getClients} from "../actions/ClientActions";
import {find, isEmpty} from "lodash";
import graphClient from "../../apis/graphClient";
import {activeLanguage, changeLanguage} from "../../../i18n";
import {defineAbilities, defineAbilitiesForAccount} from "../../casl/Ability";
import localStorageService from "../../services/localStorageService";

export const setUserData = createAsyncThunk(
    "user/getUserInfo",
    async (_, thunkApi) => {
        const {rejectWithValue, dispatch, getState} = thunkApi;

        try {
            const apiUser = await limApi.get('/userinfo').then(res => res.data.user);
            await dispatch(getClients());

            const clients = getState().clients.clientsList;
            apiUser.client = find(clients, (client) => client.idClient === apiUser.idClient) || null;

            if (!activeLanguage && !isEmpty(apiUser.cultureLanguageCode)) {
                changeLanguage(apiUser.cultureLanguageCode);
            }

            return apiUser;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const putUser = createAsyncThunk(
    'user/put',
    async ({userData}, thunkApi) => {
        const {rejectWithValue, getState} = thunkApi;
        userData.idUser = getState().user.idUser;
        try {
            if (userData.firstName !== getState().user.firstName || userData.lastName !== getState().user.lastName) {
                const user = {
                    displayName: userData.firstName + ' ' + userData.lastName,
                    givenName: userData.firstName,
                    surname: userData.lastName,
                };

                await graphClient.api(`/users/${getState().user.idUser}`).update(user);
            }

            const response = await limApi.put('/userinfo', userData);
            return response.data.user;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const changePassword = createAsyncThunk(
    'user/changePassword',
    async ({formData}, thunkApi) => {
        const {rejectWithValue, getState} = thunkApi;
        const user = {
            passwordProfile: {
                forceChangePasswordNextSignIn: false,
                password: formData.password
            }
        };

        try {
            await graphClient.api(`/users/${getState().user.idUser}`).update(user);
        } catch (e) {
            rejectWithValue(e);
        }
    }
);

export const initUserData = () => async (dispatch, getState) => {
    await dispatch(setUserData());
    const user = getState().user;

    if (user.idUser && user.accountPermissions.length > 0) {
        defineAbilitiesForAccount(user.accountPermissions);
    }

    if (user.idUser && user.permissions.length > 0) {
        defineAbilities(user.permissions);
    }

    await dispatch(setUserInitialLoading(false));
}

const initialState = {
    initialLoading: false,
    clientIdLogoImage: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserClientLogoId: (state, action) => {
            state.clientIdLogoImage = action.payload
        },
        setUserInitialLoading: (state, action) => {
            state.initialLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setUserData.pending, (state) => {
                state.initialLoading = true;
            })
            .addCase(setUserData.fulfilled, (state, action) => {
                // state.data = action.payload;
                return {...state, ...action.payload}
            })
            .addCase(setUserData.rejected, (state, action) => {
                state.initialLoading = false;
            })
            .addCase(putUser.fulfilled, (state, action) => {
                // this is fix due to wrong initial state structure
                state.firstName =  action.payload.firstName;
                state.lastName =  action.payload.lastName;
                state.mobile =  action.payload.mobile;
                state.phone =  action.payload.phone;
                state.cultureLanguageCode =  action.payload.cultureLanguageCode;
                state.company =  action.payload.company;
                state.companySite =  action.payload.companySite;
                state.department =  action.payload.department;
                state.jobTitle =  action.payload.jobTitle;
                // this is fix due to wrong initial state structure
            })
    }
});

export const {setUserClientLogoId, setUserInitialLoading} = userSlice.actions

export const selectUserIdClient = state => {
    const lsAdminCustomizerSettings = localStorageService.getItem("adminCustomizerSettings");
    let idClient = state.adminCustomizer?.settings?.idClient || lsAdminCustomizerSettings?.idClient;

    try {
        const userAccounts = state.user.accounts.filter(account => account.idClientAccount !== SYSTEM_ADMIN_ROLE);

        if (!idClient && userAccounts.length === 1) {
            idClient = userAccounts[0].idClient;
        }
    } catch (e) {}

    return idClient;
}

export const selectUserIdClientAccount = state => {
    const lsAdminCustomizerSettings = localStorageService.getItem("adminCustomizerSettings");
    let idClientAccount = state.adminCustomizer?.settings?.idClientAccount || lsAdminCustomizerSettings?.idClientAccount;

    try {
        const userAccounts = state.user.accounts.filter(account => account.idClientAccount !== SYSTEM_ADMIN_ROLE);

        if (!idClientAccount && userAccounts.length === 1) {
            idClientAccount = userAccounts[0].idClientAccount;
        }
    } catch (e) {}

    return idClientAccount;
}

export default userSlice.reducer;