import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getBoxComments = createAsyncThunk(
    "boxComment/fetchAll",
    async ({idBox, queryParams = 'sortOrders=[{"field":"creationDate","direction":"asc"}]'}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/${idBox}/comments?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postBoxComment = createAsyncThunk(
    "boxComment/create",
    async ({idBox, comment}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.post(`/box/${idBox}/comments`, {idBox, comment});
            await dispatch(getBoxComments({idBox}));

            return response.data.boxcomment;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const boxCommentSlice = createSlice({
    name: "boxComment",
    initialState: {
        list: [],
        listLoading: undefined,
    },
    reducers: {
        resetBoxComments: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBoxComments.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getBoxComments.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getBoxComments.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const {resetBoxComments} = boxCommentSlice.actions;

export default boxCommentSlice.reducer;