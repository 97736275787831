import React, { Component } from "react";
import { classList } from "@utils";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
// import Pagination from "./Pagination";
import {isEmpty} from "lodash";

class GullSearch extends Component {

  // componentDidMount() {
    // this.props.searchProducts('');
  // }

  componentWillUnmount() {
    this.clearQuery();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.productsList && prevProps.productsList !== this.props.productsList) {
      // this.setState({searchList: this.props.productsList})
    }
  }

  debounceTimer = null;
  state = {
    query: "",
    // searchList: this.props.productsList,
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.open !== this.props.open ||
        nextProps.offset !== this.props.offset ||
        nextProps.endOffset !== this.props.endOffset
        // nextProps.productsList !== this.props.productsList ||
        // nextState.searchList !== this.state.searchList
  }

  //show searched results
  handleSearch = event => {
    event.persist();
    this.setState({ query: event.target.value }, () => {
      if (this.debounceTimer) clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        if (!isEmpty(this.state.query)) {
          // this.props.searchProducts(this.state.query);
        } else {
          // this.props.resetSearchProducts();
        }
      }, 350);
    });
  };

  clearQuery = () => {
    // this.props.resetSearchProducts();
    this.setState({
      query: "",
      searchList: []
    });
    document.getElementsByClassName('search-input')[0].value = ''
  }

  render() {
    let { t, open, handleClose } = this.props;
    // let { searchList } = this.state;

    return (
      <div
        className={classList({
          "search-ui position-fixed": true,
          open: open
        })}
      >
        <div className="search-header o-hidden">
          <img
            src="/assets/images/Logo_Linkeepers_big.png"
            alt=""
            className="logo float-left"
          />
          <button
            className="search-close btn btn-icon bg-transparent float-right mt-2"
            onClick={() => {
              this.clearQuery()
              handleClose()
            }}
          >
            <i className="i-Close-Window text-22 text-dark"></i>
          </button>
        </div>

        {/*search input*/}
        <input
          type="text"
          placeholder={t("Type here")}
          className="search-input d-block w-100"
          autoFocus
          onChange={this.handleSearch}
        />

        <div className="search-title">
          <span className="text-dark">{t("Search results")}</span>
        </div>

        <div className="search-results list-horizontal" >

        </div>

        {/*{*/}
        {/*  searchList.length > 5 &&*/}
        {/*      <div className="pt-3 pb-2">*/}
        {/*        <Pagination searchList={searchList} />*/}
        {/*      </div>*/}
        {/*}*/}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
  };
}

export default connect(mapStateToProps, {
})(withRouter((withTranslation()(GullSearch))))
