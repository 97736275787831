import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getProviderSiteComments = createAsyncThunk(
    "providerSiteComment/fetchAll",
    async ({idProviderSite, queryParams = 'sortOrders=[{"field":"creationDate","direction":"asc"}]'}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/providersites/${idProviderSite}/comments?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProviderSiteComment = createAsyncThunk(
    "providerSiteComment/create",
    async ({idProviderSite, comment}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.post(`/providersites/${idProviderSite}/comments`, {idProviderSite, comment});
            await dispatch(getProviderSiteComments({idProviderSite}));

            return response.data.providerSiteComment;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const providerSiteCommentSlice = createSlice({
    name: "providerSiteComment",
    initialState: {
        list: [],
        listLoading: undefined,
    },
    reducers: {
        resetProviderSiteComments: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProviderSiteComments.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getProviderSiteComments.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getProviderSiteComments.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const {resetProviderSiteComments} = providerSiteCommentSlice.actions;

export default providerSiteCommentSlice.reducer;