import { lazy } from 'react';

const ClientList = lazy(() => import("./client/ClientList"));
const CreateClient = lazy(() => import("./client/CreateClient"));
const EditClient = lazy(() => import("./client/EditClient"));
const AccountList = lazy(() => import("./account/AccountList"));
const CreateAccount = lazy(() => import("./account/CreateAccount"));
const EditAccount = lazy(() => import("./account/EditAccount"));

const clientRoutes = [
    {
        path: "/client/list",
        component: ClientList,
        key: "View_CoreClientsClients"
    },
    {
        path: "/client/create",
        component: CreateClient,
        key: "Create_CoreClientsClients"
    },
    {
        path: "/client/edit/:idClient",
        component: EditClient,
        key: "Edit_CoreClientsClients"
    },
    {
        path: "/account/list",
        component: AccountList,
        key: "View_CoreClientsAccounts"
    },
    {
        path: "/account/create",
        component: CreateAccount,
        key: "Create_CoreClientsAccounts"
    },
    {
        path: "/account/:idClientAccount/client/:idClient",
        component: EditAccount,
        key: "Edit_CoreClientsAccounts"
    }
];

export default clientRoutes;