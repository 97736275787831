import {createAsyncThunk, createSlice, isPending, isFulfilled, isRejectedWithValue, isAnyOf} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getProvidersReport = createAsyncThunk(
    'reports/fetchProviders',
    async ({queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/reports/box/providers?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProviderSitesReport = createAsyncThunk(
    'reports/fetchProviderSites',
    async ({queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/reports/box/provider/sites?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getBoxesReport = createAsyncThunk(
    'reports/fetchBoxes',
    async ({queryParams = ""}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/reports/boxes?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const listActions = [
    getProvidersReport,
    getProviderSitesReport,
    getBoxesReport
];

const isPendingListActions = isPending(...listActions);
const isFulfilledListActions = isFulfilled(...listActions);
const isRejectedListActions = isRejectedWithValue(...listActions);

const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        list: [],
        listLoading: undefined,
        // entity: undefined,
        // loading: false
    },
    reducers: {
        resetReportList: state => {
            state.list = [];
        }
    },
    extraReducers: (builder) => {
        builder
            // lists matchers
            .addMatcher(isAnyOf(isPendingListActions), (state) => {
                state.listLoading = true
            })
            .addMatcher(isAnyOf(isFulfilledListActions), (state, action) => {
                state.listLoading = false;
                state.list = action.payload;
            })
            .addMatcher(isAnyOf(isRejectedListActions), (state) => {
                state.listLoading = false
            })
    }
});

export const {resetReportList} = reportsSlice.actions;

export default reportsSlice.reducer;