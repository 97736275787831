import { lazy } from 'react';

const Provider = lazy(() => import("./providers/Provider"));
const ProviderSites = lazy(() => import("./sites/ProviderSites"));
const CreateProvider = lazy(() => import("./providers/CreateProvider"));
const EditProvider = lazy(() => import("./providers/EditProvider"));
const CreateProviderSite = lazy(() => import("./sites/CreateProviderSite"));
const EditProviderSite = lazy(() => import("./sites/EditProviderSite"));

const providerSiteRoutes = [
    {
        path: '/provider/list',
        component: Provider,
        key: "View_CoreProviderSiteProviders"
    },
    {
        path: '/provider/create',
        component: CreateProvider,
        key: "Create_CoreProviderSiteProviders"
    },
    {
        path: '/provider/edit/:id',
        component: EditProvider,
        key: "Edit_CoreProviderSiteProviders"
    },
    {
        path: '/provider-sites/list',
        component: ProviderSites,
        key: "View_CoreProviderSiteSites"
    },
    {
        path: '/provider-sites/create',
        component: CreateProviderSite,
        key: "Create_CoreProviderSiteSites"
    },
    {
        path: '/provider-sites/edit/idProvider/:idProvider/idProviderSite/:idProviderSite',
        component: EditProviderSite,
        key: "Edit_CoreProviderSiteSites"
    }

]

export default providerSiteRoutes;