import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {getDocument} from "./documentSlice";

export const getProviderSiteDocuments = createAsyncThunk(
    'providerSiteDocument/fetchAll',
    async (idProviderSite, {rejectWithValue, dispatch}) => {
        try {
            const documents = await limApi.get(`/providersites/${idProviderSite}/documents`).then(response => response.data.documents);

            if (documents.length) {
                for (const document of documents) {
                    await dispatch(getDocument(document.idDocument));
                }
            }

            return documents;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postProviderSiteDocument = createAsyncThunk(
    'providerSiteDocument/create',
    async ({idProviderSite, documentBase64, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/providersites/${idProviderSite}/documents`,{
                idProviderSite,
                document: documentBase64,
                documentDescription: values.fileTitle,
                documentName: values.name,
                documentFileName: values.name,
                documentSize: parseInt(values.size),
                displayPosition: values.displayPosition,
                fileType: values.ext
            });

            return response.data.document;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putProviderSiteDocument = createAsyncThunk(
    'providerSiteDocument/update',
    async ({idProviderSite, idProviderSiteDocument, documentBase64, values}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/providersites/${idProviderSite}/documents/${idProviderSiteDocument}`,{
                idProviderSite,
                idProviderSiteDocument,
                idDocument: values.fileKey,
                document: documentBase64,
                documentDescription: values.fileTitle,
                documentName: values.name,
                documentFileName: values.name,
                documentSize: parseInt(values.size),
                displayPosition: values.displayPosition,
                fileType: values.ext
            });

            return response.data.document;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProviderSiteDocument = createAsyncThunk(
    "providerSiteDocument/delete",
    async ({idProviderSite, idProviderSiteDocument}, {rejectWithValue}) => {
        try {
            await limApi.delete(`/providersites/${idProviderSite}/documents/${idProviderSiteDocument}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const providerSiteDocumentsSlice = createSlice({
    name: "providerSiteDocument",
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetProviderSiteDocuments: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProviderSiteDocuments.pending, state => {
                state.listLoading = true;
            })
            .addCase(getProviderSiteDocuments.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getProviderSiteDocuments.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const updateProviderSiteDocuments = (idProviderSite, documents) => async (dispatch, getState) => {
    const providerSiteDocuments = getState().providerSiteDocument.list;

    const newDocuments = documents.filter(doc => doc.isNew);
    const existingDocuments = documents.filter(doc => !doc.isNew && providerSiteDocuments.length > 0);

    const newDocumentPromises = newDocuments.map(doc => {
        return dispatch(postProviderSiteDocument({
            idProviderSite,
            documentBase64: doc.base64.split(',')[1],
            values: doc
        }))
    });

    const existingDocumentPromise = existingDocuments.map(doc => {
        const documentDetails = providerSiteDocuments.find(item => item.idDocument === doc.fileKey);
        if (documentDetails &&
            (
                documentDetails.displayPosition !== doc.displayPosition ||
                documentDetails.documentName !== doc.fileTitle ||
                documentDetails.documentFileName !== doc.name
            )
        ) {
            return dispatch(putProviderSiteDocument({
                idProviderSite,
                idProviderSiteDocument: documentDetails.idProviderSiteDocument,
                documentBase64: doc.base64.split(',')[1],
                values: doc
            }))
        }
    });

    const orderDocumentKeys = providerSiteDocuments.map(value => value.idDocument);
    const fieldDocumentKeys = documents.map(value => value.fileKey);
    const deletedDocuments = orderDocumentKeys.filter(x => !fieldDocumentKeys.includes(x));

    const deletePromises = deletedDocuments.map(idDocument => {
        const deletedDocument = providerSiteDocuments.find(item => item.idDocument === idDocument);
        if (deletedDocument) {
            return dispatch(deleteProviderSiteDocument({idProviderSite, idProviderSiteDocument: deletedDocument.idProviderSiteDocument}))
        }
    });

    const allPromises = [...newDocumentPromises, ...existingDocumentPromise, ...deletePromises].filter(prom => prom);

    if (allPromises.length) {
        await Promise.all(allPromises);
        await dispatch(getProviderSiteDocuments(idProviderSite));
    }
}

export const {resetProviderSiteDocuments} = providerSiteDocumentsSlice.actions;

export default providerSiteDocumentsSlice.reducer;