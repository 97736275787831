import { lazy } from 'react';

const Providers = lazy(() => import("./Providers"));
const ProviderSites = lazy(() => import("./ProviderSites"));
const Boxes = lazy(() => import("./Boxes"));


const reportsRoutes = [
    {
        path: '/reports/providers',
        component: Providers,
        key: "View_CoreReportsProviders"
    },
    {
        path: '/reports/provider-sites',
        component: ProviderSites,
        key: "View_CoreReportsProviderSite"
    },
    {
        path: '/reports/boxes',
        component: Boxes,
        key: "View_CoreReportsBox"
    }
]

export default reportsRoutes;

