import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getBoxHistory = createAsyncThunk(
    "boxHistory/fetchAll",
    async ({idBox, queryParams = 'sortOrders=[{"field":"creationDate","direction":"desc"}]'}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/box/${idBox}/history?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const boxHistorySlice = createSlice({
    name: "boxHistory",
    initialState: {
        list: [],
        listLoading: undefined
    },
    reducers: {
        resetBoxHistory: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBoxHistory.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getBoxHistory.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getBoxHistory.rejected, state => {
                state.listLoading = undefined;
            })
    }
});

export const {resetBoxHistory} = boxHistorySlice.actions;

export default boxHistorySlice.reducer;